export default function compStr(str) {
	switch (str) {
		case '=':
			return 'eq'
		case '>':
			return 'gt'
		case '<':
			return 'lt'
		case '>=':
			return 'gte'
		case '<=':
			return 'lte'
		case '!=':
			return 'neq'
		default:
			return ''
	}
}
