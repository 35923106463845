import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
	Comparator,
	dateFilter,
	numberFilter,
	selectFilter,
	textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../api";
import Header from "../components/Header";
import { AuthContext } from "../contexts/authContext";
import compStr from "../utils/compStr";
import formatCurrency from "../utils/formatCurrency";
import isObjectEmpty from "../utils/isObjectEmpty";
import sortString from "../utils/sortString";

const columns = [
	{
		dataField: "id",
		text: "ID",
		sort: true,
		sortCaret: (order, column) => {
			if (!order) return <span>&nbsp;&nbsp;&darr;/&uarr;</span>;
			else if (order === "asc")
				return (
					<span>
						&nbsp;&nbsp;&darr;/<font color="red">&uarr;</font>
					</span>
				);
			else if (order === "desc")
				return (
					<span>
						&nbsp;&nbsp;<font color="red">&darr;</font>/&uarr;
					</span>
				);
			return null;
		},
		filter: numberFilter({
			defaultValue: { comparator: Comparator.EQ },
			withoutEmptyComparatorOption: true,
		}),
	},
	{
		dataField: "payment_name",
		text: "Type",
		filter: selectFilter({
			options: [
				{ value: "qpay", label: "qpay" },
				{ value: "storepay", label: "storepay" },
				{ value: "khancard", label: "khancard" },
				{ value: "khanvisa", label: "khanvisa" },
				{ value: "monpay", label: "monpay" },
				{ value: "monpayqr", label: "monpayqr" },
				{ value: "pocket", label: "pocket" },
				{ value: "socialpay", label: "socialpay" },
				{ value: "payon", label: "payon" },
			],
			placeholder: "All",
		}),
	},
	{
		dataField: "company_key",
		text: "Company Key",
		filter: selectFilter({
			options: [
				{ value: "juulchin", label: "juulchin" },
				{ value: "smart", label: "smart" },
				{ value: "loccitane", label: "loccitane" },
				{ value: "ubprint", label: "ubprint" },
				{ value: "monbeef", label: "monbeef" },
				{ value: "nura", label: "nura" },
				{ value: "godiva", label: "godiva" },
			],
			placeholder: "All",
		}),
	},
	{
		dataField: "invoice_number",
		text: "Invoice number",
		filter: textFilter(),
	},
	{
		dataField: "amount",
		text: "Amount",
		sort: true,
		formatter: formatCurrency,
		sortCaret: (order, column) => {
			if (!order) return <span>&nbsp;&nbsp;&darr;/&uarr;</span>;
			else if (order === "asc")
				return (
					<span>
						&nbsp;&nbsp;&darr;/<font color="red">&uarr;</font>
					</span>
				);
			else if (order === "desc")
				return (
					<span>
						&nbsp;&nbsp;<font color="red">&darr;</font>/&uarr;
					</span>
				);
			return null;
		},
		filter: numberFilter({
			defaultValue: { comparator: Comparator.EQ },
			withoutEmptyComparatorOption: true,
		}),
	},
	{
		dataField: "status",
		text: "Status",
		filter: selectFilter({
			options: [
				{ value: "created", label: "created" },
				{ value: "PAID", label: "paid" },
			],
			placeholder: "All",
		}),
	},
	{
		dataField: "finished_at",
		text: "Finished at",
		sort: true,
		sortCaret: (order, column) => {
			if (!order) return <span>&nbsp;&nbsp;&darr;/&uarr;</span>;
			else if (order === "asc")
				return (
					<span>
						&nbsp;&nbsp;&darr;/<font color="red">&uarr;</font>
					</span>
				);
			else if (order === "desc")
				return (
					<span>
						&nbsp;&nbsp;<font color="red">&darr;</font>/&uarr;
					</span>
				);
			return null;
		},
		formatter: (cell, row) => {
			return cell
				? moment(cell).add(8, "hours").format("YYYY/MM/DD HH:mm")
				: null;
		},
		filter: dateFilter({
			defaultValue: { comparator: Comparator.EQ },
			withoutEmptyComparatorOption: true,
		}),
	},
	{
		dataField: "created_at",
		text: "Created at",
		sort: true,
		sortCaret: (order, column) => {
			if (!order) return <span>&nbsp;&nbsp;&darr;/&uarr;</span>;
			else if (order === "asc")
				return (
					<span>
						&nbsp;&nbsp;&darr;/<font color="red">&uarr;</font>
					</span>
				);
			else if (order === "desc")
				return (
					<span>
						&nbsp;&nbsp;<font color="red">&darr;</font>/&uarr;
					</span>
				);
			return null;
		},
		formatter: (cell, row) => {
			return moment(cell).add(8, "hours").format("YYYY/MM/DD HH:mm");
		},
		filter: dateFilter({
			defaultValue: { comparator: Comparator.EQ },
			withoutEmptyComparatorOption: true,
		}),
	},
	{
		dataField: "description",
		text: "Description",
		filter: textFilter(),
	},
	{
		dataField: "mobile_number",
		text: "Mobile number",
		filter: textFilter(),
	},
];

const Invoice = () => {
	const pageTitle = "Invoices";
	const [invoices, setInvoices] = useState([]);
	const [filter, setFilter] = useState({});
	const [sort, setSort] = useState({ created_at: "desc" });
	const [user, setUser] = useState({});
	const [pagination, setPagination] = useState({
		page: 1,
		sizePerPage: 10,
		totalSize: 0,
	});
	const { getSession } = useContext(AuthContext);

	const fetchInvoices = () => {
		let idToken;
		getSession()
			.then((session) => {
				setUser(session.idToken.payload);
				idToken = session.idToken.jwtToken;
				let params = {};
				if (!isObjectEmpty(sort)) params.sortBy = sortString(sort);
				if (!isObjectEmpty(filter)) params = { ...params, ...filter };
				axios
					.get(`invoice`, {
						params,
						headers: { Authorization: idToken },
					})
					.then((resp) => {
						setInvoices(resp.data.data);
						setPagination({
							page: resp.data.pagination.page,
							sizePerPage: resp.data.pagination.pageSize,
							totalSize: resp.data.pagination.rowCount,
						});
					});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchInvoices();
	}, [sort, filter]);

	const handleTableChange = (type, newState) => {
		switch (type) {
			case "sort":
				const { sortField, sortOrder } = newState;
				let stateFilter = { ...filter };
				stateFilter.page = 1;
				setFilter({ ...stateFilter });
				setSort({ [sortField]: sortOrder });
				break;
			case "pagination":
				const { page, sizePerPage } = newState;
				let stateFilter1 = { ...filter };
				stateFilter1.page = page;
				stateFilter1.pageSize = sizePerPage;
				setFilter({ ...stateFilter1 });
				break;
			case "filter":
				const { filters } = newState;
				let updatedFilters = {};
				let tmpFilter = { ...filter };
				for (const [key, value] of Object.entries(filters)) {
					delete tmpFilter[key];
					switch (value.filterType) {
						case "TEXT":
							updatedFilters = {
								...updatedFilters,
								[key]: `like:${value.filterVal}`,
							};
							break;
						case "SELECT":
							updatedFilters = {
								...updatedFilters,
								[key]: `eq:${value.filterVal}`,
							};
							break;
						case "NUMBER":
							if (value.filterVal.number)
								updatedFilters = {
									...updatedFilters,
									[key]: `${compStr(value.filterVal.comparator)}:${
										value.filterVal.number
									}`,
								};
							break;
						case "DATE":
							// console.log(`${key}=${value.filterVal.comparator}:${moment(value.filterVal.date).set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD')}`)
							if (value.filterVal.date)
								updatedFilters = {
									...updatedFilters,
									[key]: `${compStr(value.filterVal.comparator)}:${moment
										.tz(value.filterVal.date, "Asia/Ulaanbaatar")
										.set("hour", 0)
										.set("minute", 0)
										.set("second", 0)
										.unix()}`,
								};
							break;
						default:
							break;
					}
					tmpFilter = { ...tmpFilter, ...updatedFilters };
				}
				setFilter(updatedFilters);
				break;
			default:
				break;
		}
	};

	return (
		<div>
			<Header head={pageTitle} />
			<BootstrapTable
				hover={true}
				condensed={true}
				keyField="id"
				data={invoices}
				columns={columns}
				pagination={paginationFactory({ ...pagination, showTotal: true })}
				remote={{
					filter: true,
					pagination: true,
					sort: true,
					cellEdit: true,
				}}
				headerClasses="text-sm"
				rowStyle={{ fontSize: "12px" }}
				onTableChange={handleTableChange}
				filter={filterFactory()}
				filterPosition={"top"}
			/>
		</div>
	);
};

export default Invoice;
