import Header from '../components/Header'

const Requests = () => {
  // page content
  const pageTitle = 'Requests'

  return (
    <div>
      <Header head={pageTitle}/>
    </div>
  )
}

export default Requests
