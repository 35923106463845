import React from 'react'
import { Container } from 'react-bootstrap'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../pages/Login'

const LoginLayout = () => {
	return (
		<Container className="mt-5" style={{maxWidth: '500px'}}>
			<h2 className='text-center mb-3'>Payment Service</h2>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="*" element={<Navigate to="/login" />} />
			</Routes>
		</Container>
	)
}

export default LoginLayout
