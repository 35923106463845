import { Link } from "react-router-dom";
import { Nav, Container } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";

const Menu = () => {
	const { logout } = useContext(AuthContext)
	const signout = (e) => {
		e.preventDefault()
		logout()
	}
	return (
		<Container>
			<header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
				<Link
					to="/"
					className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
				>
					Payment Service
				</Link>
				<Nav>
					<ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
						<li>
							<Link to="/" className="nav-link px-2 link-secondary">
								Home
							</Link>
						</li>
						{/* <li>
							<Link to="/request" className="nav-link px-2 link-secondary">
								Requests
							</Link>
						</li> */}
						<li>
							<Link to="/invoice" className="nav-link px-2 link-secondary">
								Invoices
							</Link>
						</li>
						{/* <li>
							<Link to="/config" className="nav-link px-2 link-secondary">
								Configs
							</Link>
						</li> */}
						<li>
							<a href="#" onClick={signout} className="nav-link px-2 link-secondary">
								Logout
							</a>
						</li>
					</ul>
				</Nav>
			</header>
		</Container>
	);
};

export default Menu;
