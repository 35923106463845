
// Layout
import Layout from "./layout";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer } from 'react-toastify';
import { Auth } from "./contexts/authContext";
import UserPool from "./UserPool";
import { useEffect, useState } from "react";

const App = () => {
	const [loggedIn, setLoggedIn] = useState(false)
	const [checkState, setCheckState] = useState(false)
	useEffect(() => {
		const user = UserPool.getCurrentUser()
		if (user) {
			user.getSession((err, session) => {
				if (err) {
					setLoggedIn(false)
				} else {
					setLoggedIn(true)
				}
			})
		}
		setCheckState(true)
	}, [])


	return checkState && <Auth loggedIn={loggedIn}>
		<Layout />
		<ToastContainer />
	</Auth>
};

export default App;
