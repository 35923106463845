
// Layout
import MainLayout from "./Main";

import { useContext } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer } from 'react-toastify';
import { AuthContext } from "../contexts/authContext";
import LoginLayout from "./Login";

const Layout = () => {
	const { isLoggedIn } = useContext(AuthContext)
	return <>
		{isLoggedIn ? <MainLayout /> : <LoginLayout />}
		<ToastContainer />
	</>
};

export default Layout;
