import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import axios from '../api'
import Header from '../components/Header'
import { AuthContext } from '../contexts/authContext'

const Home = () => {
	const pageTitle = 'Home'
	const { getSession } = useContext(AuthContext)
	const [requestsHourly, setRequestsHourly] = useState([])
	const [invoicesDaily, setInvoicesDaily] = useState([])


	useEffect(() => {
		const fetchDash = async () => {
			let idToken
			getSession().then(session => {
				idToken = session.idToken.jwtToken;
				axios.get(`dashboard/requests_by_hour`, { headers: { Authorization: idToken } }).then(resp => {
					setRequestsHourly(resp.data.map(row => {
						return { name: moment(row.key).format('HH:mm'), count: row.value }
					}))
				})
				axios.get(`dashboard/invoices_by_day`, { headers: { Authorization: idToken } }).then(resp => {
					setInvoicesDaily(resp.data.map(row => {
						return { name: moment(row.key).format('MMM DD'), count: row.value }
					}))
				})
			}).catch(err => {
				console.log(err)
			})
		}
		fetchDash()
	}, [])


	return (
		<div style={{ width: '100%' }}>
			<Header head={pageTitle} />
			<Row className='mb-4'>
				<Col>
					<h5 className='text-center'>Last 24 hours requests</h5>
					<ResponsiveContainer width="100%" height={300}>
						<AreaChart
							width={500}
							height={300}
							data={requestsHourly}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="4" />
							<XAxis dataKey="name" interval={'preserveStartEnd'} />
							<YAxis />
							<Tooltip />
							<Area type="monotone" dataKey="count" stroke="#8884d8" name={'Requests'} />
						</AreaChart>
					</ResponsiveContainer>
				</Col>
				<Col>
					<h5 className='text-center'>Last month invoices</h5>
					<ResponsiveContainer width="100%" height={300}>
						<AreaChart
							width={500}
							height={300}
							data={invoicesDaily}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="4" />
							<XAxis dataKey="name" interval={'preserveStartEnd'} />
							<YAxis />
							<Tooltip />
							<Area type="monotone" dataKey="count" stroke="#8884d8" name={'Invoices'} />
						</AreaChart>
					</ResponsiveContainer>
				</Col>
			</Row>
		</div>
	)
}

export default Home
