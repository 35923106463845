import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { createContext, useState } from 'react';
import Pool from '../UserPool';

const AuthContext = createContext()

const Auth = (props) => {
	const [isLoggedIn, setIsLoggedIn] = useState(props.loggedIn)
	const [cognitoUser, setCognitoUser] = useState(null)

	const getSession = async () => {
		return await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser()
			if (user) {
				user.getSession((err, session) => {
					if (err) {
						reject()
					} else {
						resolve(session)
					}
				})
			} else {
				reject()
			}
		})
	}
	const authenticate = async (email, password) => {
		return await new Promise((resolve, reject) => {
			const user = new CognitoUser({
				Username: email,
				Pool
			})
			const authDetails = new AuthenticationDetails({
				Username: email,
				Password: password
			})
			user.authenticateUser(authDetails, {
				onSuccess: (data) => {
					setIsLoggedIn(true)
					resolve({ type: 'success', data })
				},
				onFailure: (err) => {
					reject(err)
				},
				newPasswordRequired: userAttr => {
					setCognitoUser(user)
					resolve({ type: 'newpassword', data: userAttr })
				},
			})
		})
	}
	const logout = () => {
		const user = Pool.getCurrentUser()
		if (user) {
			user.signOut()
			setIsLoggedIn(false)
			// window.location.reload()
		}
	}
	const newPassword = async (password, userAttr) => {
		delete userAttr.email_verified
		return await new Promise((resolve, reject) => {
			cognitoUser.completeNewPasswordChallenge(password, userAttr, {
				onSuccess: data => {
					resolve(data)
					setIsLoggedIn(true)
				},
				onFailure: (err) => {
					reject(err)
				},
			})
		})
	}
	return (
		<AuthContext.Provider value={{ authenticate, cognitoUser, getSession, logout, newPassword, isLoggedIn }}>
			{props.children}
		</AuthContext.Provider>
	)
}
export { Auth, AuthContext };

