export default function sortString (obj) {
	let str = ''
	if (obj) {
		for (const [key, value] of Object.entries(obj)) {
			if (str !== '')
				str += ','
			str += `${key}:${value}`
		}
	}
	return str
}
