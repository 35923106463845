import Header from '../components/Header'

const Configs = () => {
  // page content
  const pageTitle = 'Configs'

  return (
    <div>
      <Header head={pageTitle} />
    </div>
  )
}

export default Configs
