import { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { AuthContext } from '../contexts/authContext';
const Login = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [firstLogin, setFirstLogin] = useState(false)
	const [changePassword, setChangePassword] = useState({})

	const { authenticate, newPassword } = useContext(AuthContext)

	const handleLoginSubmit = (event) => {
		event.preventDefault()

		authenticate(email, password)
			.then(({ type, data }) => {
				if (type === 'success') {
					// window.location.reload()
					toast.success('Login successful!', {
						position: "top-center",
						autoClose: 2500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				} else if (type === 'newpassword') {
					setFirstLogin(true)
					setChangePassword({ ...changePassword, userAttr: data })
					toast.warn('Please, change your password.', {
						position: "top-center",
						autoClose: 2500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				}
			})
			.catch(err => {
				console.error('onFailure: ', err)
				toast.error(err.message, {
					position: "top-center",
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			})
	}
	const handleNewPasswordSubmit = (event) => {
		event.preventDefault()
		if (changePassword.password === changePassword.confirmPassword) {
			newPassword(changePassword.password, changePassword.userAttr)
				.then(data => {
					toast.success('Login successful!', {
						position: "top-center",
						autoClose: 2500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				})
				.catch(err => {
					// console.log('new pass err: ', err.message)
					toast.error(err.message, {
						position: "top-center",
						autoClose: 2500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				})
		} else {
			toast.error('Confirm password doesn\'t match the password', {
				position: "top-center",
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
		}
	}
	return (
		<Container>
			{!firstLogin ?
				<Form onSubmit={handleLoginSubmit}>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicPassword" onChange={(e) => setPassword(e.target.value)} value={password}>
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Password" />
					</Form.Group>
					<Button variant="primary" type="submit">
						Submit
					</Button>
				</Form>
				:
				<Form onSubmit={handleNewPasswordSubmit}>
					<Form.Group className="mb-3" controlId="formBasicChangePassword">
						<Form.Label>New password</Form.Label>
						<Form.Control type="password" placeholder="New password" onChange={(e) => setChangePassword({ ...changePassword, password: e.target.value })} value={changePassword.password ?? ''} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicChangePasswordConfirm" onChange={(e) => setChangePassword({ ...changePassword, confirmPassword: e.target.value })}>
						<Form.Label>Confirm new password</Form.Label>
						<Form.Control type="password" placeholder="Confirm New password" value={changePassword.confirmPassword ?? ''} />
					</Form.Group>
					<Button variant="primary" type="submit">
						Submit
					</Button>
				</Form>
			}
		</Container>
	)
}

export default Login
