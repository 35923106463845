// components
import Footer from "../components/Footer.jsx";
import Menu from "../components/Menu";
// pages
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../contexts/authContext.js";
import Configs from "../pages/Configs";
import Home from "../pages/Home";
import Invoices from "../pages/Invoices";
import Login from "../pages/Login";
import Requests from "../pages/Requests";

const Layout = ({ children }) => {

	return (
		<>
			<Menu />
			<Container>
				<main>
					<Routes>
						<Route path="/" element={<Home />} exact />
						<Route path="/invoice" element={<Invoices />} />
						<Route path="/request" element={<Requests />} />
						{/* <Route path="/config" element={<Configs />} /> */}
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</main>
			</Container>
		</>
	)
}

export default Layout;
